import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import suddathForm from '../assets/images/suddath-form.png'
import reactLogo from '../assets/images/react-logo.png'
import wordpressLogo from '../assets/images/wordpress-logo.png'
import scssLogo from '../assets/images/scss-logo.png'
import nextJsLogo from '../assets/images/next-js.png'
import hubspotLogo from '../assets/images/hubspot-logo.png'
import gutenLogo from '../assets/images/gutenberg-logo.png'
import suddathLogo from '../assets/images/suddath-green.png'
import suddathMap from '../assets/images/suddath-map.png'

const Landing = props => (
  <Layout>
    <Helmet
      title="Tyler Kemme | Suddath"
      meta={[
        {
          name: 'description',
          content: 'Headless Wordpress React Site',
        },
        {
          name: 'keywords',
          content:
            'suddath, wordpress, react, headless, next, hubspot, gutenberg',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>

    <BannerLanding
      title="Suddath"
      description="Headless Wordpress + React Site"
      to="https://www.suddath.com/"
      backgroundImage={suddathLogo}
    />

    <div id="main" className="landing-page">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h2>The Project</h2>
          </header>
          <p>
            Suddath wanted a complete redesign of their company's website so we
            chose to use React, Hubspot, and Next.js for the front-end and
            Wordpress as a headless CMS. We used Gutenberg editor for Wordpress
            to allow the client to design the entire website themselves. The
            site was developed locally using Docker and hosted on WPEngine.
          </p>
        </div>
      </section>
      <section id="two" className="spotlights">
        <section>
          <div className="logo-soup">
            <img
              src={reactLogo}
              alt="React logo"
              className="logo-soup-individual"
            />
            <img
              src={wordpressLogo}
              alt="Wordpress logo"
              className="logo-soup-individual"
            />
            <img
              src={scssLogo}
              alt="SCSS logo"
              className="logo-soup-individual"
            />
            <img
              src={nextJsLogo}
              alt="Next.js logo"
              className="logo-soup-individual"
            />
            <img
              src={hubspotLogo}
              alt="Hubspot logo"
              className="logo-soup-individual"
            />
            <img
              src={gutenLogo}
              alt="Gutenberg logo"
              className="logo-soup-individual"
            />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h2>Technologies Used</h2>
              </header>
              <p>
                This Headless Wordpress/React site was made using the following
                front-end technologies, developed locally using Docker, and
                hosted on WPEngine
                <br />
                <br />
                <ul>
                  <li>
                    <a
                      href="https://reactjs.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      React
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://wordpress.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Wordpress
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://sass-lang.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SCSS
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://nextjs.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Next.js
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.hubspot.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Hubspot
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://wordpress.org/gutenberg/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Gutenberg
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="image">
            <img src={suddathMap} alt="Suddath Map Page" />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>How I contributed</h3>
              </header>
              <p>
                I was responsible for the store locator page as well as all of
                the forms across the entire site. The store locator was made by
                retrieving store Information from Wordpress and generating the
                map using the Google Maps API. The forms were custom
                implementations of Hubspot forms that used the Hubspot API to
                submit data to Hubspot's CRM platform.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="image">
            <img src={suddathForm} alt="Suddath form" />
          </div>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Additional Information</h3>
              </header>
              <p>
                The forms on this site were difficult to develop because by
                appearance there is only one form variant. In reality, form
                questions varied depending on the particular page and on
                previous questions answered by users. Each variant corresponded
                to a different form in Hubspot's backend and all of this had to
                be developed dynamically. Hubspot does not have the ability to
                do multi-page forms so they were created manually using
                Javascript and Hubspot's API.
              </p>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
)

export default Landing
